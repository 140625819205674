import * as React from 'react';
import $ from 'jquery';
import cx from 'classnames';
import px from 'prop-types';
import Slider from 'react-slick';
import { ArrowButton } from 'Common/components/ui';
import { Product } from 'Common/types';
import { CatalogQuickView } from 'Common/components/catalog';
import * as GTM from 'Common/constants/gtm';
import { GTM as gtmUtil } from 'Common/utils';

const getSliderSettings = (slidesToShow) => ({
    slidesToShow,
    slidesToScroll: 1,
    centerMode: true,
    dots: false,
    focusOnSelect: true,
    infinite: true,
    speed: 500,
    initialSlide: 0,
    prevArrow: <ArrowButton direction="left" ariaHidden />,
    nextArrow: <ArrowButton direction="right" ariaHidden />,
    responsive: [
        {
            breakpoint: 1199,
            settings: { slidesToShow: 3 },
        },
        {
            breakpoint: 991,
            settings: { slidesToShow: 2 },
        },
        {
            breakpoint: 767,
            settings: {
                focusOnSelect: false,
                slidesToShow: 1,
                dots: true,
                slidesToScroll: 1,
            },
        },
    ],
});

export default function ProductRecommendations({
    className,
    checkoutPageLink,
    isUserAuthenticated,
    ProductPartialView,
    products = [],
    RecommendedProductCard,
    slidesToShow = 3,
    trackProductQuickviewClick,
    itemStatusTooltip,
}) {
    const [productData, setProductData] = React.useState(null);
    const [showQuick, setShowQuick] = React.useState(false);
    const gtmImpression = React.useRef(false);
    const slickSliderSettings = React.useMemo(
        () => getSliderSettings(products.length > 2 ? slidesToShow : products.length),
        [products.length, slidesToShow]
    );

    const setQuick = React.useCallback(
        (product) => () => {
            setProductData({ Product: product });
            trackProductQuickviewClick(product.Code);
            setShowQuick(true);
        },
        [setProductData, trackProductQuickviewClick]
    );

    const hideQuickView = React.useCallback(() => setShowQuick(false), [setShowQuick]);

    const onSelectProduct = React.useCallback(
        (product, index, onClick) => () => {
            gtmUtil.updateDataLayer(gtmUtil.mapEntityToImpClick(product, GTM.TAGS.RECOMMENDED_QUICK, index, onClick));
            onClick();
        },
        []
    );

    React.useEffect(() => {
        $('div.slick-slide[aria-hidden="true"]').attr('aria-hidden', false);
    }, []);

    React.useEffect(() => {
        if (!gtmImpression.current && products?.length) {
            gtmUtil.updateDataLayer(gtmUtil.mapEntityToImpressions(products, GTM.TAGS.RECOMMENDED_QUICK));
            gtmImpression.current = true;
        }
    }, [products]);

    return (
        <div className={cx('ProductRecommendations', className)}>
            <Slider {...slickSliderSettings}>
                {products.map((product, index) => (
                    <RecommendedProductCard
                        key={product.Code}
                        index={index}
                        product={product}
                        onOpenQuick={setQuick}
                        onSelectProduct={onSelectProduct}
                    />
                ))}
            </Slider>
            {showQuick ? (
                <CatalogQuickView
                    Product={ProductPartialView}
                    productProps={{
                        productData: productData,
                        checkoutPageLink: checkoutPageLink,
                        gtmListValue: GTM.TAGS.RECOMMENDED_QUICK,
                    }}
                    isUserAuthenticated={isUserAuthenticated}
                    onClose={hideQuickView}
                    itemStatusTooltip={itemStatusTooltip}
                />
            ) : null}
        </div>
    );
}

ProductRecommendations.propTypes = {
    className: px.string,
    checkoutPageLink: px.string,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    ProductPartialView: px.elementType,
    products: px.arrayOf(Product),
    RecommendedProductCard: px.elementType,
    slidesToShow: px.number,
    trackProductQuickviewClick: px.func,
    itemStatusTooltip: px.string,
};
