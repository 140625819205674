import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';
import { useTranslation, useViewport } from 'Common/hooks';
import * as STYLE from '~config/style';

export default function DropdownSelector({
    className,
    options = [],
    selectedValues = [],
    title = '',
    onSelect,
    outOfStockToolTip,
    notAvailableToolTip,
    disabledMap,
    definition = null,
    singleRowHeight = `${STYLE.FILTER_COLAPSED_ROW_HEIGHT ?? 42}px`,
}) {
    const viewport = useViewport();
    const optionsRef = React.useRef(null);
    const id = React.useRef(`dropdown_${v4()}`);
    const lblExpand = useTranslation('Commerce.Product.Selector.Expand.Label');
    const lblHide = useTranslation('Commerce.Product.Selector.Hide.Label');
    const [expanded, setExpanded] = React.useState(true);
    const [expandedAll, setExpandedAll] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);

    const optionIsDisabled = React.useCallback(
        ({ value } = {}) =>
            disabledMap && (disabledMap === true || disabledMap[value]?.isDisabled || !disabledMap[value]?.isValid),
        [disabledMap]
    );

    const onClick = React.useCallback((value) => (onSelect ? () => onSelect(value) : null), [onSelect]);

    React.useEffect(() => {
        optionsRef.current.style.height = expandedAll
            ? `${optionsRef.current.children[0]?.getBoundingClientRect()?.height}px`
            : singleRowHeight;
    }, [expandedAll, singleRowHeight]);

    React.useEffect(() => {
        const optionsContainer = optionsRef.current?.children[0];
        const children = optionsContainer?.children || [];

        for (let i = 0, l = children.length; i < l; i++) {
            if (i > 0 && children[i - 1].offsetTop < children[i].offsetTop) {
                setHidden(true);
                return;
            }
        }
        setHidden(false);
    }, [viewport, options]);

    const buttonTitle = React.useCallback(
        (value) => {
            if (disabledMap && (disabledMap[value]?.isDisabled || !disabledMap[value]?.isValid))
                return notAvailableToolTip;
            else if (disabledMap && !disabledMap[value]?.hasStock) return outOfStockToolTip;

            return value;
        },
        [outOfStockToolTip, notAvailableToolTip, disabledMap]
    );

    return (
        <div className={cx('DropdownSelector container dropdown-options', className)}>
            <button
                className="DropdownSelector__toggle btn"
                type="button"
                data-toggle="collapse"
                data-target={`#${id.current}`}
                aria-expanded="false"
                aria-controls="dropdown-selector"
                onClick={() => setExpanded(!expanded)}
            >
                <div className="row d-flex align-items-center">
                    <p className="mb-0">{title}</p>
                    <i className={`fa fa-chevron-${expanded ? 'up' : 'down'}`} />
                </div>
            </button>
            <div className="collapse show" id={id.current}>
                <div
                    ref={optionsRef}
                    className="btn-toolbar justify-content-center justify-content-md-start"
                    style={{ transition: 'height .3s ease' }}
                    role="toolbar"
                >
                    <div className="btn-group flex-wrap col-10" role="group">
                        {options.map((option) => (
                            <button
                                key={option.value}
                                value={option.value}
                                title={buttonTitle(option.value)}
                                data-toggle="tooltip"
                                className={cx('btn mt-1', {
                                    selected: selectedValues.includes(option.value),
                                    oos: !disabledMap[option.value]?.hasStock,
                                    disabled: !disabledMap[option.value]?.hasStock || optionIsDisabled(option),
                                })}
                                onClick={onClick(option.value)}
                            >
                                <p>{option.label}</p>
                            </button>
                        ))}
                        {definition ? (
                            <div className="definition mt-2">
                                <p className="light-sm">{definition}</p>
                            </div>
                        ) : null}
                    </div>
                    {hidden ? (
                        <button
                            className="btn dropdown col-2"
                            type="button"
                            onClick={() => setExpandedAll(!expandedAll)}
                            aria-label={expandedAll ? lblHide : lblExpand}
                            title={expandedAll ? lblHide : lblExpand}
                        >
                            <i className={`fa fa-chevron-${expandedAll ? 'up' : 'down'}`} />
                        </button>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

DropdownSelector.propTypes = {
    className: px.string,
    title: px.string,
    options: px.arrayOf(px.shape({ label: px.string, value: px.string })),
    selectedValues: px.arrayOf(px.oneOfType([px.string, px.number])),
    outOfStockToolTip: px.string,
    notAvailableToolTip: px.string,
    disabledMap: px.objectOf(px.object),
    onSelect: px.func,
    definition: px.node,
    singleRowHeight: px.string,
};
