import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { useViewport } from 'Common/hooks';
import { Product } from 'Common/types';
import { Url, GTM as gtmUtil } from 'Common/utils';
import * as GTM from 'Common/constants/gtm';
import * as PRODUCT from '~config/product';
import { Translation } from '../localization';
import { Loader } from '../ui';
import ProductPrice from './ProductPrice';

export default function RelatedProducts({ className, relatedProducts }) {
    const viewport = useViewport();
    const gtmImpression = React.useRef(false);
    const showProducts = React.useMemo(() => !viewport.is.mobile && relatedProducts?.length, [
        viewport,
        relatedProducts,
    ]);

    const onSelectProduct = React.useCallback(
        (product, index, onClick) => () => {
            gtmUtil.updateDataLayer(gtmUtil.mapEntityToImpClick(product, GTM.TAGS.RELATED, index, onClick));
            onClick();
        },
        []
    );

    React.useEffect(() => {
        if (!gtmImpression.current && showProducts && relatedProducts?.length) {
            gtmUtil.updateDataLayer(gtmUtil.mapEntityToImpressions(relatedProducts, GTM.TAGS.RELATED));
            gtmImpression.current = true;
        }
    }, [relatedProducts, showProducts]);

    return (
        <div className={cx('RelatedProducts text-center', className)}>
            <div className="RelatedProducts__title">
                <Translation id="Commerce.Product.RelatedProducts.Title" />
            </div>
            {relatedProducts?.length ? (
                relatedProducts.map((product, index) => (
                    //TODO: EDS-3143
                    <div key={product.Code} className="RelatedProducts__product mt-4">
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a
                            href={product?.ContentUrl}
                            target={new URL(product?.ContentUrl).host === location.host ? '_self' : '_blank'}
                            rel={
                                new URL(product?.ContentUrl).host === location.host ? undefined : 'noopener noreferrer'
                            }
                            onClick={onSelectProduct(product, index)}
                            aria-label={product.DisplayName}
                        >
                            <img
                                className="RelatedProducts__product__image fit-image-main"
                                src={Url.addToRelativeUrl(
                                    product.DefaultImageUrl,
                                    `format=pngheight=175&width=250${
                                        PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
                                    }`
                                )}
                                alt={product.DisplayName}
                            />
                            <div className="subheader">{product.DisplayName}</div>
                            {product.Price ? (
                                <ProductPrice
                                    priceInfo={{ price: product.Price, useQtySalePrice: false }}
                                    quantity={1}
                                />
                            ) : null}
                        </a>
                    </div>
                ))
            ) : relatedProducts ? null : (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                    <Loader noOverlay />
                </div>
            )}
        </div>
    );
}

RelatedProducts.propTypes = {
    className: px.string,
    relatedProducts: px.arrayOf(Product),
};
