import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import VideoPlayerModal from './VideoPlayerModal';
import { createPortal } from 'react-dom';
import Icon from './Icon';

export default function VideoPlayerButton({ style, className, src, color }) {
    const [modalEl, setModalEl] = React.useState(null);
    const [isVideoPlaying, setVideoPlaying] = React.useState(false);

    const startVideo = React.useCallback(() => setVideoPlaying(true), []);
    const stopVideo = React.useCallback(() => setVideoPlaying(false), []);

    const videoProps = React.useMemo(
        () => ({
            url: src,
            playing: true,
        }),
        [src]
    );

    React.useEffect(() => {
        const el = document.createElement('div');

        el.tabIndex = -1;
        document.body.appendChild(el);

        setModalEl(el);

        return () => {
            el.remove();
        };
    }, []);

    return (
        <>
            <span
                role="button"
                style={style}
                className={cx('VideoPlayerButton', `VideoPlayerButton--${color}`, className)}
                onClick={startVideo}
            >
                <Icon name="play" color={color} />
            </span>
            {modalEl && isVideoPlaying
                ? createPortal(
                      <VideoPlayerModal onClose={stopVideo} show={isVideoPlaying} videoProps={videoProps} />,
                      modalEl
                  )
                : undefined}
        </>
    );
}

VideoPlayerButton.propTypes = {
    style: px.object,
    className: px.string,
    src: px.string.isRequired,
    color: px.string,
};
