import * as strings from 'Common/constants/gtm';

export default class GTM {
    static mapEntityToAddCart(entity, quantity = 1, productAttributes, listValue) {
        return {
            event: 'addToCart',
            ecommerce: {
                currencyCode: entity.Price.SalePrice.Currency,
                add: {
                    actionField: {
                        list: listValue,
                    },
                    products: [
                        {
                            name: entity.DisplayName,
                            id: entity.Code,
                            price: entity.Price.SalePrice.Amount * quantity,
                            currencyCode: entity.Price.SalePrice.Currency,
                            brand: productAttributes.find((a) => a.AttributeName === 'ProductBrand')?.Values[0] ?? null,
                            category:
                                productAttributes.find((a) => a.AttributeName === 'ProductCategory')?.Values[0] ?? null,
                            family:
                                productAttributes.find((a) => a.AttributeName === 'ProductFamily')?.Values[0] ?? null,
                            series:
                                productAttributes.find((a) => a.AttributeName === 'ProductSeries')?.Values[0] ?? null,
                            quantity,
                        },
                    ],
                },
            },
        };
    }

    static mapEntityToRemoveCart(products, listValue) {
        return {
            event: 'removeFromCart',
            ecommerce: {
                remove: {
                    actionField: { list: listValue },
                    products: products.map((product) => ({
                        name: product.DisplayName,
                        id: product.Code,
                        price: product.Price.SalePrice.Amount,
                        currencyCode: product.Price.SalePrice.Currency,
                        quantity: product.Quantity,
                        brand: product.Attributes.find((a) => a.AttributeName === 'ProductBrand')?.Values[0],
                        category: product.Attributes.find((a) => a.AttributeName === 'ProductCategory')?.Values[0],
                        family: product.Attributes.find((a) => a.AttributeName === 'ProductFamily')?.Values[0],
                        series: product.Attributes.find((a) => a.AttributeName === 'ProductSeries')?.Values[0],
                    })),
                },
            },
        };
    }

    static mapEntityToImpressions(products, listValue) {
        const firstProduct = products?.length ? products[0] : null;

        return {
            event: 'impressionView',
            ecommerce: {
                currencyCode: firstProduct?.Price?.SalePrice?.Currency,
                impressions: products.map((product, indx) => ({
                    name: product.DisplayName,
                    id: product.Code,
                    price: product.Price.SalePrice.Amount,
                    brand: product.Attributes.find((a) => a.AttributeName === 'ProductBrand')?.Values[0],
                    category: product.Attributes.find((a) => a.AttributeName === 'ProductCategory')?.Values[0],
                    list: listValue,
                    position: indx,
                })),
            },
        };
    }

    static mapEntityToImpClick(product, listValue, position, onClick) {
        return {
            event: 'productClick',
            ecommerce: {
                click: {
                    actionField: { list: listValue }, // Optional list property.
                    products: [
                        {
                            name: product.DisplayName,
                            id: product.Code,
                            price: product.Price.SalePrice.Amount,
                            brand: product.Attributes.find((a) => a.AttributeName === 'ProductBrand')?.Values[0],
                            category: product.Attributes.find((a) => a.AttributeName === 'ProductCategory')?.Values[0],
                            position: position,
                        },
                    ],
                },
            },
            eventCallback: onClick,
        };
    }

    static mapEntitiesToCheckoutStep(products, step) {
        return {
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField: { step: step },
                    products: products.map((product) => ({
                        name: product.DisplayName,
                        id: product.Code,
                        price: product.Price.SalePrice.Amount,
                        brand: product.Attributes.find((a) => a.AttributeName === 'ProductBrand')?.Values[0],
                        category: product.Attributes.find((a) => a.AttributeName === 'ProductCategory')?.Values[0],
                    })),
                },
            },
        };
    }

    static getEmailSignup(signupLocation) {
        return {
            event: 'emailsignup',
            emailsignup: {
                location: signupLocation,
            },
        };
    }

    static mapEntityToFormSubmission(formCategory, formName) {
        return {
            event: 'formSubmission',
            formCategory,
            formName,
        };
    }

    static updateDataLayer(gtmPayload, layer = strings.LAYERS.ECOMMERCE) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ [layer]: null });
        window.dataLayer.push(gtmPayload);
    }
}
